<template>
    <div class="main">
        <div class="center">
            <img style="vertical-align: middle; height: 400px; margin: 10px" :src="require('@/assets/notFound.png')"/>
            <p class="parag">Missing Website Configuration!</p>
            <!-- <a class="btn-link" href="#">Go Back Home</a> -->
        </div>
    </div>
    
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({

});

</script>
<style>
/* :root {
	--hue: 223;
	--bg: hsl(var(--hue),10%,90%);
	--fg: hsl(var(--hue),10%,10%);
	--primary: hsl(var(--hue),90%,45%);
	--primary-down: hsl(var(--hue),90%,65%);
	--trans-dur: 0.3s;
	font-size: calc(16px + (24 - 16) * (100vw - 320px) / (2560 - 320));
} */
.main {
    height: 100%;
    text-align: center;
    background-color: rgb(217, 225, 230);
}
.center {
    height: 90vh;
    text-align: center;
    align-content: center;
  font-weight: bold;
  font-family: Tahoma;
}
.parag {
    font-size: 2em;
	margin: 0 0 1.5em;
}
.btn-link {
	background: var(--primary);
	border-radius: 1.5em;
	display: inline-block;
	outline: transparent;
	padding: 0.75em 1.5em;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.15s linear;
}
.btn-link,
.btn-link:visited {
	color: hsl(0,0%,100%);
}
.btn-link:focus-visible,
.btn-link:hover {
	background: var(--primary-down);
}
.btn-link:hover {
	text-decoration: none;
}

/* Beyond mobile */

</style>