<template>
    <div class="tiptap-content" v-html="html" :style="{'color': color}"></div>
</template>

<script lang="ts">

import Vue from 'vue';

export default Vue.extend({
    components: { },
    props: {
        html: {
            type: String,
        },
        color: {
            type: String,
        }
    },
});

</script>