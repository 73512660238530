import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#008080",
            secondary: "#36454F",
            accent: "#E30B5C",
          },
        },
      },
      icons: {
        iconfont: "mdi", // default - only for display purposes
      },
});
