import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import "tiptap-vuetify/dist/main.css";

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: (h) => 
    h(App, {
      props: {},
    }),
}).$mount('#app')
