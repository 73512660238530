<template>
    <v-sheet rounded="lg" style="margin-top: 0;">
      <v-row>
        <v-col>
          <h2>Available Jobs</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined color="transparent">
            <v-card-text>
              <v-data-table
            :dense="false"
            :items="jobs"
            :headers="headers"
          >
          <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData"></slot>
      </template>
          <template v-slot:[`header.actions`]=""></template>
          <template v-slot:[`item.startDate`]="{ item }">
            {{ formatDateStr(item.startDate) }}
          </template>
          <template v-slot:[`item.employerId`]="{ item }">
            <div>{{ getEmployerName(item, contractors) }}</div>
          </template>
        </v-data-table>
            </v-card-text>
          </v-card>
          
        </v-col>
      </v-row>
        <v-row v-for="(section, index) in callOutSections" :key="index">
            <v-col >
                <JobBoardTableComponent 
                    :job-board-dto-list="groupItemsBySectionId(section.id || '')"
                    :section-name="section.name"
                    :bodyTextColor="bodyTextColor"
                />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script lang="ts">
import Vue from 'vue';
import { JobBoardDtoView, CallOutSectionDto, Job, Employer } from './types';
import { client } from '@/services/dispatch-client';
import JobBoardTableComponent from "./JobBoardTableComponent.vue";

export default Vue.extend({
    components: {
        JobBoardTableComponent,
    },
    props: {
      bodyTextColor: {
        type: String,
      },
    },
mounted() {
    this.ListJobBoard();
    this.ListCallOutSection();
    this.getJobs();
    this.getContractors();
},
data() {
  return {
    list: [] as JobBoardDtoView[],
    callOutSections: [] as CallOutSectionDto[],
    jobs: [] as Job[],
    contractors: [] as Employer[],
    headers: [
        { text: "Start Date", value: "startDate", class: 'my-header-style' },
        { text: "Company", value: "employerId", class: 'my-header-style' },
        { text: "Project", value: "employerProject.name", class: 'my-header-style' },
        { text: "Job Status", value: "status", class: 'my-header-style' },
        { text: "Required", value: "required", class: 'my-header-style' },
        { text: "Filled", value: "filled", class: 'my-header-style' },
    ]
  }  
},
methods: {
  async getJobs() {
    try {
      const res = await client.get<Job[]>("Job");
      this.jobs = res.response;
    }
    finally {
      console.log("Success");
    }
  },
  async getContractors() {
      const response = await client.get<Employer[]>("Employer");
      this.contractors = response.response;
  },
  getEmployerName(job: Job, employers: Employer[]): string | null {
      const employer = employers.find((emp) => emp.id === job.employerId);
      return employer ? employer.companyName : null;
    },
    async ListCallOutSection() {
      const res = await client.get<CallOutSectionDto[]>("CallOutSection");
      this.callOutSections = res.response;
    },
    async ListJobBoard() {
      const resp = await client.get<JobBoardDtoView[]>("JobBoard");
      this.list = resp.response;
    },
    groupItemsBySectionId(idSection: string) {
      if (!idSection) {
        return [];
      }
      const filteredList = this.list.filter(
        (item) => item.callOutSectionId === idSection
      );
      return filteredList;
    },
    formatDateStr(dateString?: string): string {
    // Si la cadena de fecha es null, generamos la fecha de hoy
    if (!dateString) {
      return "";
    }

    // Si la cadena de fecha no es null, la convertimos a formato personalizado
    const formattedDate = new Date(dateString);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = formattedDate.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
}
});
</script>
<style>
.v-data-table th span {
  text-transform: capitalize !important;
  color: black;
}

.v-data-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.06);
  
}
</style>