<template>
  <div>
    <v-app-bar height="88" app dense elevation="5" :color="themeColor">
      <v-toolbar-title>
        <v-row>
          <v-col style="padding-top: 25px">
            <v-btn
              text
              @click.stop="showSidebar = !showSidebar"
              :style="{ color: themeTextColor }"
            >
              <v-icon>mdi-view-list</v-icon>
              Menu
            </v-btn>
          </v-col>
          <v-col>
            <router-link to="/">
              <img
                style="vertical-align: middle; height: 50px; margin: 10px"
                :src="logo"
              />
            </router-link>
          </v-col>
          <v-col style="padding-top: 25px">
            <v-btn
              :ripple="false"
              class="no-background-hover"
              text
              :style="{ color: themeTextColor }"
              style="font-size: 1.2em"
              @click="changeRoute('website.home')"
              >{{ webSetting.title }}</v-btn
            >
          </v-col>
        </v-row>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      v-model="showSidebar"
      app
      :color="themeColor"
      width="260"
    >
      <v-list :style="{ 'background-color': themeColor }">
        <v-list-item :style="{ 'background-color': themeColor }">
          <v-btn
            min-width="25"
            :style="[
              { color: themeTextColor },
              { 'text-transform': 'unset !important' },
              { 'font-size': '16px' },
            ]"
            text
            @click="changeRoute('website.home')"
            >Home</v-btn
          >
        </v-list-item>
        <v-list-item :style="{ 'background-color': themeColor }">
          <v-btn
            min-width="25"
            :style="[
              { color: themeTextColor },
              { 'text-transform': 'unset !important' },
              { 'font-size': '16px' },
            ]"
            text
            @click="changeRoute('website.jobs')"
            >Available Jobs</v-btn
          >
        </v-list-item>
        <div v-if="webSections.length > 0">
          <v-list-item
            v-for="webSection in webSections"
            :key="webSection.id"
            :style="{ 'background-color': themeColor }"
          >
            <v-btn
              min-width="25"
              :style="[
                { color: themeTextColor },
                { 'text-transform': 'unset !important' },
                { 'font-size': '16px' },
              ]"
              text
              @click="
                changeRouteSection(webSection.sectionName, webSection.content)
              "
              >{{ webSection.sectionName }}</v-btn
            >
          </v-list-item>
        </div>

        <v-list-item>
          <v-btn
            href="http://localhost:8082"
            min-width="25"
            :style="[
              { color: themeTextColor },
              { 'text-transform': 'unset !important' },
              { 'font-size': '16px' },
            ]"
            text
            >Member Login</v-btn
          >
        </v-list-item>
        <v-list-item>
          <v-btn
            min-width="25"
            href="http://localhost:8080"
            target="_blank"
            :style="[
              { color: themeTextColor },
              { 'text-transform': 'unset !important' },
              { 'font-size': '16px' },
            ]"
            text
            >Employer Login</v-btn
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <div
        style="height: 680px"
        :style="{ 'background-color': backgroundColor }"
      >
        <div class="hero" style="width: 100%">
          <div class="opacity-child" :style="{ opacity: opacity }">
            <v-carousel
              cycle
              hide-delimiter-background
              :show-arrows="false"
              hide-delimiters
              height="680"
            >
              <v-carousel-item
                v-for="(item, i) in itemsCarousel"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
          </div>

          <div class="hero-child" style="opacity: 0.999">
            <v-container>
              <h1
                :style="[
                  { color: webSetting.titleColor },
                  { 'font-size': '60px' },
                ]"
              >
                {{ webSetting.title }}
              </h1>
              <h2
                :style="[
                  { color: webSetting.descriptionColor },
                  { 'font-size': '30px' },
                ]"
              >
                {{ webSetting.description }}
              </h2>
            </v-container>
          </div>
        </div>
      </div>
    </v-main>
    <v-btn @click="topFunction" id="btnScroll" icon
      ><v-icon>mdi-arrow-up</v-icon></v-btn
    >
    <v-main
      style="min-height: 70vh; padding-top: 20px"
      :style="{ color: webSetting.bodyTextColor }"
    >
      <v-container
        v-if="!isHome"
        style="max-width: 960px; text-align: justify; text-justify: inter-word"
      >
        <router-view :content="pageContent" />
      </v-container>
      <v-container
        v-if="isHome"
        style="max-width: 1040px; text-align: justify; text-justify: inter-word"
      >
        <HomePageView
          :content="webSetting.homePage"
          :bodyTextColor="webSetting.bodyTextColor"
        />
      </v-container>
      <v-container
        v-if="isHome"
        style="max-width: 1040px; text-align: justify; text-justify: inter-word"
      >
        <AvailableJobsView :bodyTextColor="webSetting.bodyTextColor" />
      </v-container>
      <v-container
        v-if="isHome"
        style="max-width: 1040px; text-align: justify; text-justify: inter-word"
      >
        <ContactUsView :bodyTextColor="webSetting.bodyTextColor" />
      </v-container>
    </v-main>

    <v-main>
      <div :style="{ 'background-color': themeColor }">
        <v-container style="text-align: center">
          <v-btn
            :style="{ color: themeTextColor }"
            text
            @click="changeRoute('website.contact')"
            >Contact Us</v-btn
          >
          <span :style="{ color: themeTextColor }">|</span>
          <v-btn
            :style="{ color: themeTextColor }"
            text
            @click="changeRoute('website.privacypolicy')"
            >Privacy Policy</v-btn
          >
          <span :style="{ color: themeTextColor }">|</span>
          <v-btn
            :style="{ color: themeTextColor }"
            text
            @click="changeRoute('website.termsofservice')"
            >Terms of Service</v-btn
          >
        </v-container>
        <v-container style="text-align: center">
          <FooterInformation
            :html="webSetting.footerInformation"
            :color="webSetting.themeTextColor"
          />
        </v-container>
      </div>
    </v-main>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { client } from "@/services/dispatch-client";
import { WebSettingsDto, WebSettingSectionsDto } from "./types";
import AvailableJobsView from "./AvailableJobsView.vue";
import ContactUsView from "./ContactUsView.vue";
import FooterInformation from "./FooterInformationView.vue";
import HomePageView from "./HomePageView.vue";

export default Vue.extend({
  components: {
    AvailableJobsView,
    ContactUsView,
    FooterInformation,
    HomePageView,
  },
  props: {
    show: Boolean,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      loading: false,
      webSetting: {} as WebSettingsDto,
      webSections: [] as WebSettingSectionsDto[],
      pageContent: "",
      title: "",
      heroImage: "",
      logo: "",
      icon: "",
      opacity: "",
      backgroundColor: "",
      themeTextColor: "",
      themeColor: "",
      bottom: true,
      showSidebar: true,
      itemsCarousel: [
        {
          src: "",
        },
      ],
    };
  },
  mounted() {
    this.getWebSettings();
  },
  computed: {
    isHome() {
      return this.$route.name == "website.home";
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let myBtn = document.getElementById("btnScroll");
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        myBtn!.style.display = "block";
      } else {
        myBtn!.style.display = "none";
      }
    },
    topFunction() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    async getWebSettings() {
      try {
        this.loading = true;
        const res = await client.get<WebSettingsDto>("WebSettings");
        if (res.response != null) {
          this.webSetting = res.response;
          this.webSections = this.webSetting.webSettingSections;
          this.title = this.webSetting.title;
          this.opacity = this.webSetting.opacity;
          this.backgroundColor = this.webSetting.backGroundColor;
          this.themeColor = this.webSetting.themeColor;
          this.themeTextColor = this.webSetting.themeTextColor;
          document.title = this.title;

          if (this.webSetting.heroLogoId) {
            var ids = this.webSetting.heroLogoId.split(",");
            this.itemsCarousel = [];
            for (let i = 0; i < ids.length; i++) {
              this.heroImage = (
                await client.get<string>(`file/${ids[i]}/dataurl`)
              ).response;
              this.itemsCarousel.push({
                src: this.heroImage,
              });
            }
          }
          if (this.webSetting.logoId)
            this.logo = (
              await client.get<string>(`file/${this.webSetting.logoId}/dataurl`)
            ).response;

          if (this.webSetting.faviconId) {
            this.icon = (
              await client.get<string>(
                `file/${this.webSetting.faviconId}/dataurl`
              )
            ).response;
            var link = document.createElement("link");
            link.rel = "icon";
            link.href = this.icon;
            document.head.appendChild(link);
          }
        } else {
          this.$router.push({ name: "website.notfound" });
        }
      } finally {
        this.loading = false;
      }
    },
    changeContent(content: string) {
      this.pageContent = content;
    },
    changeRoute(routeName: string) {
      this.$router.push({ name: routeName });
    },
    changeRouteSection(sectionName: string, content: string) {
      this.$router.push({
        name: "websiteroot",
        params: { sectionName: sectionName },
      });
      this.pageContent = content;
    },
  },
});
</script>
<style>
.no-background-hover::before {
  background-color: transparent !important;
}
.hero {
  position: relative;
  /* background-size: cover;*/
  text-align: center;
  align-content: center;
}
.hero-child {
  position: absolute;
  top: 230px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.opacity-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.btn-fullscreen {
  position: relative;
  top: 10px;
  right: 10px;
}

#btnScroll {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #8f911c;
  color: white;
  cursor: pointer;
  padding-left: 5px;
  height: 60px;
  width: 60px;
}

.rounded-btn {
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-color: #2c3e50;
  color: white;
}

.v-list-item {
  padding-left: 16px;
}

.v-list-group__header .v-list-item {
  padding-left: 16px;
}

.v-list-group .v-list-item {
  padding-left: 32px;
}
</style>
