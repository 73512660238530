<template>
    <div>
      <v-card outlined color="transparent">
        <!-- <v-card-title><h2>Contact Us</h2></v-card-title> -->
        <v-card-text>
            <div class="tiptap-content" :style="{'color': bodyTextColor}" v-html="content"></div>
        </v-card-text>
      </v-card>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
    props: {
        content: {
            type: String,
            default: ""
        },
        bodyTextColor: {
            type: String,
        }
    },
});
</script>