<template>
    <div>
        <div class="tiptap-content" v-html="terms"></div>
        <v-btn @click="topFunction" id="btnScroll" icon><v-icon>mdi-arrow-up</v-icon></v-btn>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { client }  from '../services/dispatch-client';
import { WebSettingsDto, WebSettingSectionsDto } from './types';

export default Vue.extend({
    data() {
        return {
            terms: "",
            loading: false,
            webSetting: {} as WebSettingsDto,
            webSections: [] as WebSettingSectionsDto[],
        }
    },
    mounted() {
        this.getWebSettings()
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            let myBtn = document.getElementById("btnScroll");
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                myBtn!.style.display = "block";
            }
            else {
                myBtn!.style.display = "none";
            }
        },
        topFunction() {
            // document.body.scrollTop = 0;
            // document.documentElement.scrollTop = 0;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        },
        async getWebSettings() {
            try {
                const res = await client.get<WebSettingsDto>("WebSettings");
                this.webSetting = res.response;
                this.terms = this.webSetting.termsOfService
            }
            finally {
                this.loading = false;
            }
        },
    }
});
</script>
<style>
#btnScroll {
    display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color:  #8f911c;;
  color: white;
  cursor: pointer;
  padding-left: 5px;
  height: 60px;
  width: 60px;
}
</style>