<template>
    <div>
        <div v-if="content.length > 0" class="tiptap-content" v-html="content"></div>
        <div v-if="content.length == 0" class="tiptap-content" v-html="pageContent"></div>
    </div>
    
</template>

<script lang="ts">
import Vue from 'vue';
import { client }  from './services/dispatch-client';
import { WebSettingsDto } from './components/types';
// import FooterInformation from "./components/FooterInformationView.vue";

export default Vue.extend({
    components: {  },
    props: {
        token: {
            type: String,
        },
        dispatchUrl: {
            type: String,
            default: "http://localhost:5143/api"
        },
        unionId: {
            type: String,
            default: "0fd6ceea-9e3c-49eb-a032-7b9f124f3067", // Test Union
        },
        content: {
            type: String,
        }
    },
    data() {
        return {
            pageContent: "",
        }
    },
    mounted() {
        this.getWebSettings();
    },
    methods: {
        async getWebSettings() {
            try {
                const res = (await client.get<WebSettingsDto>("WebSettings")).response;
                if (res != null) {
                    var param = this.$route.params
                    res.webSettingSections.forEach(e => {
                        if (e.sectionName == param['sectionName']) {
                            this.pageContent = e.content;
                        }
                    });
                }
                else {
                    this.$router.push({ name: "website.notfound"})
                }
            }
            finally {
                console.log('')
            }
        },
    },
});

</script>

<style>
.no-background-hover::before {
    background-color: transparent !important;
}
.btn-fullscreen {
  position: relative;
  top: 10px;
  right: 10px;
}

#btnScroll {
    display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color:  #8f911c;;
  color: white;
  cursor: pointer;
  padding-left: 5px;
  height: 60px;
  width: 60px;
}

.rounded-btn {
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-color: #2c3e50;
  color: white;
}

.v-list-item {
  padding-left: 16px;
}

.v-list-group__header .v-list-item {
  padding-left: 16px;
}

.v-list-group .v-list-item {
  padding-left: 32px;
}
</style>