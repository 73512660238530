<template>
    <div >
      <v-card outlined color="transparent">
        <v-card-text>
          <v-toolbar flat>
        <v-toolbar-title>{{ SectionName }}</v-toolbar-title>
      </v-toolbar>
      <v-simple-table fixed-header>
        <thead>
          <tr>
            <th class="text-left font-weight-bold">Full Name</th>
            <th class="text-left font-weight-bold">Sign In Date</th>
            <th class="text-left font-weight-bold">Call Out Status</th>
            <th class="text-left font-weight-bold">Qualifications</th>
            <th class="text-left font-weight-bold">Call Out Location</th>
          </tr>
        </thead>
        <tbody v-if="jobBoardDtoList.length > 0">
          <tr v-for="item in jobBoardDtoList" :key="item.id">
            <td>{{ item.firstName }}</td>
            <td>{{ item.signInDate }}</td>
            <td>{{ item.callOutStatus }}</td>
            <td>
              <span
                v-for="(qualification, index) in item.qualificationsName"
                :key="index"
              >
                {{ qualification }}
              </span>
            </td>
            <td>{{ item.callOutLocation }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" style="text-align: center">
              No members available for call out
            </td>
          </tr>
        </tbody>
      </v-simple-table>
        </v-card-text>
      </v-card>
      
    </div>
  </template>
  
  <script lang="ts">
  import Vue from "vue";
  import { JobBoardDtoView } from "./types";
  
  export default Vue.extend({
    name: "JobBoardTable",
    components: {},
    props: {
      show: Boolean,
      SectionName: String,
      bodyTextColor: String,
      jobBoardDtoList: {
        type: Array as () => JobBoardDtoView[],
        required: true,
      },
    },
    data() {
      return {
        headers: [
          { text: "FirstName", value: "firstName" },
          { text: "SignInDate", value: "signInDate" },
          { text: "Qualifications", value: "qualificationsName" },
          { text: "CallOutStatus", value: "callOutStatus" },
          { text: "CallOutLocation", value: "callOutLocation" },
        ],
        list: [] as JobBoardDtoView[],
      };
    },
    methods: {},
  });
  </script>
  <style>
 .v-simple-table th span {
  text-transform: capitalize !important;
  color: black;
}

.v-simple-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.06);
}
</style>
  