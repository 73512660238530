<template>
    <div >
      <v-card outlined color="transparent" :style="{'color': webSettings.bodyTextColor}">
        <v-card-title><h2 >Contact Us</h2></v-card-title>
        <v-card-text :style="{'color': webSettings.bodyTextColor}">
            <v-row>
                <v-col v-if="websiteContact.length > 0">
                    <v-list lines="two">
                        <v-list-item style="padding: 20px;" v-for="(item, index) in websiteContact" :key="index">
                                <v-row>
                                    <v-col md="2" style="align-content: center;">
                                        <a id="no-background-hover" v-if="item.contactType == 'Phone Number'" class="test_cursor"><v-icon size="35" color="#3777d2">{{ `mdi-${item.contactType.toLowerCase()}` }}</v-icon></a>
                                        <a id="no-background-hover" :href="`mailto:${item.contactEmail}`" v-if="item.contactType == 'Email Address'" class="test_cursor"><v-icon size="35" color="#3777d2">{{ `mdi-${item.contactType.toLowerCase()}` }}</v-icon></a>
                                        <a v-if="item.contactType != 'Phone Number' && item.contactType != 'Email Address'" :href="item.contactLink" target="_blank">
                                            <v-icon size="35" color="#3777d2">{{ `mdi-${item.contactType.toLowerCase()}` }}</v-icon>
                                        </a>
                                    </v-col>
                                    <v-col style="align-content: center;" md="5"><a :href="item.contactLink" class="contact_link" target="_blank" style="color: black; ">{{ item.contactName }}</a></v-col>
                                    <v-col style="align-content: center;" md="4" v-if="item.contactType == 'Phone Number'"><h3>{{ item.contactPhone }}</h3></v-col>
                                    <v-col style="align-content: center;" md="5" v-if="item.contactType == 'Email Address'"><a :href="`mailto:${item.contactEmail}`" class="contact_link" style="color: black; ">{{ item.contactEmail }}</a></v-col>
                                </v-row>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col>
                <form id="contact" @submit.prevent="sendMessage" >
                    <v-card-text>
                        <v-text-field v-model="contactUs.subject" label="Subject" :style="{'color': webSettings.bodyTextColor}"></v-text-field>
                        <v-text-field v-model="contactUs.name" label="Name"></v-text-field>
                        <v-text-field v-model="contactUs.email" label="Email"></v-text-field>
                        <v-textarea v-model="contactUs.message" label="Type your message"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <slot name="actions" v-bind="{ form: $refs.form}">
                            <v-spacer></v-spacer>
                            <SubmitAction 
                            :loading="loading"
                            formId="contact"/>
                        </slot>
                    </v-card-actions>
                </form>
                </v-col>
                <v-col v-if="websiteContact.length == 0">
                    <div v-if="webSettings.officeAddress.length > 0">
                    <iframe
                        height="500"
                        width="100%"
                        style="border:0;" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade"
                        :src="mapsUrl"
                        name="map"
                        id="map"
                    >
                    </iframe>
            </div>
                </v-col>
            </v-row>
            <div v-if="webSettings.officeAddress.length > 0 && websiteContact.length > 0">
                <iframe
                    height="500"
                    width="100%"
                    style="border:0;" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                    :src="mapsUrl"
                    name="map"
                    id="map"
                >
                </iframe>
            </div>
        </v-card-text>
      </v-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { client } from '@/services/dispatch-client';
import { WebsiteContactDto, WebSettingsDto, ContactUsDto  } from './types';
import SubmitAction from "@/components/commons/SubmitAction.vue"

export default Vue.extend({
    components: { SubmitAction },
    props: {
        bodyTextColor: {
            type: String,
        },
    },
    data() {
        return {
            websiteContact: [] as WebsiteContactDto[],
            webSettings: {} as WebSettingsDto,
            contactUs: {} as ContactUsDto,
            loading: false,
            mapsUrl: "",
        };
    },
    mounted() {
        this.getWebsiteContact();

    },
    methods: {
        async getWebsiteContact() {
            try {
                this.webSettings = (await client.get<WebSettingsDto>("WebSettings")).response;
                if (this.webSettings.id) {
                    this.websiteContact = (await client.get<WebsiteContactDto[]>(`WebSettings/WebsiteContact/${this.webSettings.id}`)).response;
                    if (this.webSettings.officeAddress.length > 0)
                        this.googleMapsURLToEmbedURL(this.webSettings.officeAddress)
                }
            }
            finally {
                console.log("fina")
            }
        },
        async sendMessage() {
            try {
                this.loading = true;
                await client.post<ContactUsDto>("WebSettings/WebsiteContact/ContactMessage", this.contactUs);
                this.contactUs = {
                    email: "",
                    message: "",
                    name: "",
                    subject: ""
                };
            }
            finally {
                this.loading = false;
            }
        },
        googleMapsURLToEmbedURL(url: string) {
            var src = "https://maps.google.com/maps?q=" + this.encodeAdreess(url) +
            "&amp;t=m&amp;&z=15" +
            "&output=embed&amp;iwloc=near";

            this.mapsUrl = src;
        },
        encodeAdreess(str: string) {
            return encodeURIComponent(str).replace(
                " ",
                "%20"
            );
        },
    }
});
</script>

<style>
.test_cursor {
  cursor: default;
}
#no-background-hover::before {
   background-color: transparent !important;
}
.contact_link {
    text-decoration: none; 
    color: black; 
    font-size: 1.1em; 
    font-weight: bold;
}
</style>