import { RouteConfig } from "vue-router";
import TermsOfServiceView from "@/components/TermsOfServiceView.vue";
import PrivacyPolicyView from "@/components/PrivacyPolicyView.vue";
import WebsiteRoot from "@/WebsiteRoot.vue";
import HomeView from "@/components/HomeView.vue";
import AvailableJobs from "@/components/AvailableJobsView.vue";
import ContactUsView from "@/components/ContactUsView.vue";
import NotFoundView from "@/components/NotFoundView.vue"

const routes: Array<RouteConfig> = [
    {
        path: "",
        name: "website.home",
        component: HomeView
    },
    {
        path: "jobs",
        name: "website.jobs",
        component: AvailableJobs
    },
    {
        path: "contact-us",
        name: "website.contact",
        component: ContactUsView
    },
    {
        path: "sections/:sectionName",
        name: "websiteroot",
        component: WebsiteRoot
    },
    {
        path: "legal/terms-of-service",
        name: "website.termsofservice",
        component: TermsOfServiceView
    },
    {
        path: "legal/privacy-policy",
        name: "website.privacypolicy",
        component: PrivacyPolicyView
    },
    {
        path: "not-found",
        name: "website.notfound",
        component: NotFoundView
    },
];

export function websiteRoutes(prefix: string = ""): Array<RouteConfig> {
    return routes.map((route) => ({
        ...route,
        path: prefix + route.path,
    }));
}
