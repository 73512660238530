import Vue from "vue";
import VueRouter from "vue-router";
import { websiteRoutes } from "./website-router-lib";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: websiteRoutes("/"),
});

export default router;